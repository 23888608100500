import React, { Component, useContext, useCallback } from 'react';
import Gallery from "react-photo-gallery";
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import Navbar from "../components/Navbar/Navbar";
import SelectedImage from '../components/Photo/SelectedImage';
import photos from '../components/Photo/Photo.js';
import Selector from "../components/LanguageSelector/Selector.js";
import Fade from 'react-reveal/Fade';
import '../i18n/i18n';
import colours from "../style/colours";

const PhotoPage = () => {
    const [selectAll, setSelectAll] = React.useState(false);
    const [isDesktop, setIsDesktop] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState('en');

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    React.useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
      }, [selectedLanguage]);


    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    };

    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
            <Fade bottom duration={1000} delay={400 + (index * 50)} distance="30px">
                <SelectedImage
                    selected={selectAll ? true : false}
                    key={key}
                    margin={"2px"}
                    index={index}
                    photo={photo}
                    left={left}
                    top={top}
                    downloadLabel={selectedLanguage === 'en' ? "Download" : "Télécharger"}
                />
            </Fade>
        ),
        [selectAll]
    );
    return (
        <div>
            <Navbar />
            {/* <Selector setSelectedLanguage={setSelectedLanguage} /> */}
            <Fade duration={1000} delay={500} distance="30px">
                <div style={{ marginTop: 110 }}>
                    <div 
                        style={{ 
                            fontSize: isDesktop ? 40 : 30, 
                            color: 'white', 
                            borderRadius: 4, 
                            fontWeight: 500,

                            textShadow: `${colours.primary} 0px 0px 50px`,
                        }}
                    >{t('photography.t')}</div>
                    <div style={{ marginTop: 10, marginLeft: 20, marginRight: 20, fontSize: 14 }}>{t('photography.h1')}</div>
                    <div style={{ marginBottom: 40, marginLeft: 20, marginRight: 20, fontSize: 14 }}>{t('photography.h2')} {t('photography.h3')} <a style={{ backgroundImage: `linear-gradient(135deg, ${colours['primary']} 0%, ${colours['secondary']} 100%)`, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", fontSize: 15, fontWeight: 'bold' }} href="https://www.flickr.com/photos/186287830@N06/" target="blank">Flickr</a>{t('photography.h4')}</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div 
                            style={{ 
                                "height": "100%", 
                                "width": "95%", 
                                padding: 6, 
                                borderRadius: 12, 
                            }}
                        >
                            <Gallery photos={photos} renderImage={imageRenderer} columns={2} />
                        </div>

                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default PhotoPage