const photos = [
    {
        src: 'https://vivek.kandathil.ca/photos/A_23.jpg',
        width: 4.5,
        height: 3,
        location: 'Kottayam, Kerala, India',
        locationLink: 'https://www.google.com/maps/place/Kottayam,+Kerala,+India/@9.5946171,76.4426515,15546m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3b062ba16c6b435f:0xbe2b02f68f8dd06e!8m2!3d9.5915668!4d76.5221531!16zL20vMGI1NjY4?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_3 copy 5.jpg',
        width: 4.5,
        height: 3,
        location: 'Montréal, Québec, Canada',
        locationLink: 'https://www.google.com/maps/place/Montreal,+QC/@45.5590642,-73.8766798,44157m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cc91a541c64b70d:0x654e3138211fefef!8m2!3d45.5018869!4d-73.5673919!16zL20vMDUycDc?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_20.jpg',
        width: 4.5,
        height: 4,
        location: 'Kottayam, Kerala, India',
        locationLink: 'https://www.google.com/maps/place/Kottayam,+Kerala,+India/@9.5946171,76.4426515,15546m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3b062ba16c6b435f:0xbe2b02f68f8dd06e!8m2!3d9.5915668!4d76.5221531!16zL20vMGI1NjY4?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_22.jpg',
        width: 4,
        height: 3,
        location: 'Kottayam, Kerala, India',
        locationLink: 'https://www.google.com/maps/place/Kottayam,+Kerala,+India/@9.5946171,76.4426515,15546m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3b062ba16c6b435f:0xbe2b02f68f8dd06e!8m2!3d9.5915668!4d76.5221531!16zL20vMGI1NjY4?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_3 copy 4.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_21.jpg',
        width: 4.5,
        height: 3,
        location: 'Kottayam, Kerala, India',
        locationLink: 'https://www.google.com/maps/place/Kottayam,+Kerala,+India/@9.5946171,76.4426515,15546m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3b062ba16c6b435f:0xbe2b02f68f8dd06e!8m2!3d9.5915668!4d76.5221531!16zL20vMGI1NjY4?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/house.jpg',
        width: 4.5,
        height: 3,
        location: 'Pictou, Nova Scotia, Canada',
        locationLink: 'https://www.google.com/maps/place/Pictou,+NS/@45.6778184,-62.7339798,5508m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4b5ea1c8b6b56277:0xd3026362ae656532!8m2!3d45.6761282!4d-62.708845!16zL20vMDI1NW1k?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/wave.jpg',
        width: 5,
        height: 3,
        location: 'Bay of Fundy, Canada',
        locationLink: 'https://www.google.com/maps/place/Bay+of+Fundy/@45.03587,-66.5903966,356534m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4ca787d5965e4fa7:0xee010da77c4a4579!8m2!3d44.7728488!4d-66.309551!16zL20vMGhtM2w?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/splant1.jpg',
        width: 5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/fishing_net.jpg',
        width: 5,
        height: 3,
        location: 'Kochi, Kerala, India',
        locationLink: 'https://www.google.com/maps/place/Kochi,+Kerala,+India/@9.982516,76.1361224,62112m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3b080d514abec6bf:0xbd582caa5844192!8m2!3d9.9312328!4d76.2673041!16zL20vMGZsMnM?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/splant2.jpg',
        width: 5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/Night2.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/parliament.jpg',
        width: 9,
        height: 6,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_2.jpg',
        width: 4.8,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/spider.jpg',
        width: 5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/Gatineau_A.JPG',
        width: 3,
        height: 3,
        location: 'Gatineau, Québec, Canada',
        locationLink: 'https://www.google.com/maps/place/Gatineau,+QC/@45.4853806,-75.7917328,44215m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce1a7e0babee53:0x7cedf5701a140956!8m2!3d45.4765446!4d-75.7012723!16zL20vMHBtbDc?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_7 2.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_7 copy 2.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_9.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/36190657380_cf003f191c_o.jpg',
        width: 5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/Chateau_Laurier.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/schamplain.jpg',
        width: 4.6,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/streets4.jpg',
        width: 6,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/senna2.jpg',
        width: 4.8,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/sky.jpeg',
        width: 5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/stone.jpg',
        width: 5,
        height: 3,
        location: 'Pictou, Nova Scotia, Canada',
        locationLink: 'https://www.google.com/maps/place/Pictou,+NS/@45.6778184,-62.7339798,5508m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4b5ea1c8b6b56277:0xd3026362ae656532!8m2!3d45.6761282!4d-62.708845!16zL20vMDI1NW1k?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/stone2.jpg',
        width: 4.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/splant4.jpg',
        width: 5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/stulips.jpg',
        width: 3.5,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/streets.jpg',
        width: 4.5,
        height: 3,
        location: 'Montréal, Québec, Canada',
        locationLink: 'https://www.google.com/maps/place/Montreal,+QC/@45.5590642,-73.8766798,44157m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cc91a541c64b70d:0x654e3138211fefef!8m2!3d45.5018869!4d-73.5673919!16zL20vMDUycDc?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/IMG_5575.jpg',
        width: 4.5,
        height: 6.5,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_6.jpg',
        width: 4,
        height: 3,
        location: 'Ottawa, Ontario, Canada',
        locationLink: 'https://www.google.com/maps/place/Ottawa,+ON/@45.2496824,-76.1298926,88800m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4cce05b25f5113af:0x8a6a51e131dd15ed!8m2!3d45.4200572!4d-75.7003397!16zL20vMDVrc2g?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D',
    },
];

export default photos