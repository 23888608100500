import React, { useState, useEffect } from "react";
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import "./SelectedImage.css"
import { propTypes } from "react-bootstrap/esm/Image";
import colours from "../../style/colours";

const Checkmark = ({ selected }) => (
    <div
        style={
            selected
                ? { left: "4px", top: "4px", position: "absolute", zIndex: "1" }
                : { display: "none" }
        }
    >
        <svg
            style={{ fill: "white", position: "absolute" }}
            width="24px"
            height="24px"
        >
            <circle cx="12.5" cy="12.2" r="8.292" />
        </svg>
        <svg
            style={{ fill: colours['secondary'], position: "absolute" }}
            width="24px"
            height="24px"
        >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </svg>
    </div>
);

const imgStyle = {
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
    borderRadius: 12,
};
const selectedImgStyle = {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const cont = {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative"
};

const SelectedImage = ({
    index,
    photo,
    margin,
    direction,
    top,
    left,
    selected,
    downloadLabel
}) => {
    const [isSelected, setIsSelected] = useState(selected);
    const [hovering, setHovering] = React.useState(false);

    //calculate x,y scale
    const sx = (100 - (30 / photo.width) * 100) / 100;
    const sy = (100 - (30 / photo.height) * 100) / 100;
    selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

    if (direction === "column") {
        cont.position = "absolute";
        cont.left = left;
        cont.top = top;
    }

    const handleOnClick = e => {
        setIsSelected(!isSelected);
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div
            style={{ margin, height: photo.height, width: photo.width, ...cont }}
            class="container-card"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <Link to={photo.src}>
                <Checkmark selected={isSelected ? true : false} />
                <img 
                    class="image-card" 
                    {...photo} 
                    onMouseDown={handleOnClick} 
                    style={isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }} 
                    alt={"Picture"} 
                />
                <div class="middle">
                    <a href={photo?.locationLink} target='_blank' style={{ color: 'white', width: 300, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                        {photo?.location}
                    </a>
                    <button class="download-button" role="button">{downloadLabel}</button>
                </div>
            </Link>

        </div>
    );
};

export default SelectedImage;
